import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { DocumentViewerState } from './state';
import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.VUE_APP_URL_BACKEND;

const actions: ActionTree<DocumentViewerState, RootState> = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async fetchDocumentUrls({ commit }, { document, type }: { document: string; type: string }) {
        try {
            const response = await axios.get(`${API_URL}api/auth/view-document-autorization`, {
                params: { document, type },
                headers: {
                    Authorization: `Bearer ${Cookies.get('authToken')}` // Si se requiere autenticación
                },
                responseType: 'blob'
            });

            const pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

            if (type === 'autorizacion') {
                commit('setAutorizacionPdfUrl', pdfUrl);
            } else if (type === 'instrucciones') {
                commit('setInstruccionesPdfUrl', pdfUrl);
            } else if (type === 'pagare') {
                commit('setPagarePdfUrl', pdfUrl);
            }
        } catch (error) {
            console.error('Error fetching document URL:', error);
        }
    },
    // Obtener el PDf de términos y condiciones desde el backend
    async fetchTermsPdf({ commit }) {
        try {
            const response = await axios.get(`${API_URL}terminos/pdf/`, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('authToken')}`
                },
                responseType: 'blob'
            });

            // Crear una URL en el estado global
            const pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf'}));

            // Guardamos la URL en el estado global
            commit('setTermsPdfUrl', pdfUrl);

            return pdfUrl;
        } catch (error) {
            console.error('Error obteniendo el PDF de términos y condiciones:', error);
            throw error;
        }
    },
};
  
  export default actions;