import { MutationTree } from 'vuex';
import { DocumentViewerState } from './state';

const mutations: MutationTree<DocumentViewerState> = {
  setAutorizacionPdfUrl(state, url: string) {
    state.autorizacionPdfUrl = url;
  },
  setInstruccionesPdfUrl(state, url: string) {
      state.instruccionesPdfUrl = url;
  },
  setPagarePdfUrl(state, url: string) {
      state.pagarePdfUrl = url;
  },
  setTermsPdfUrl(state, pdfUrl: string) {
      state.termsPdfUrl = pdfUrl;
  },
};
    
export default mutations;